
.login-content{
    background-image: url('../../assets/img/login.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    font-family: 'Numans', sans-serif;
}

.login-content .h-100vh{
    height: 100vh !important;
}

.login-content .card .card-header{
    background-color: transparent !important;
    display: flex;
    justify-content: center;
}

.login-content .container{
    height: 100%;
    align-content: center;
}

.p-0{
    padding: 0 !important;
}

.login-content .logo-login{
    max-width: 200px;
}

.login-content .card{
    /*height: 370px;*/
    height: 330px;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: rgba(0,0,0,0.5) !important;
}

.login-content .card-header h3{
    color: white;
}

.login-content .input-group-prepend span{
    width: 50px;
    background-color: rgb(243, 146, 0);
    color: black;
    border:0 !important;
}

.login-content input:focus{
    outline: 0 0 0 0  !important;
    box-shadow: 0 0 0 0 !important;

}

.login-content .remember{
    color: white;
}

.login-content .remember input
{
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

.login-content .login_btn{
    color: black;
    background-color: rgb(243, 146, 0);
    width: 100%;
}

.login-content .login_btn:hover{
    color: black;
    background-color: white;
}

.login-content .links{
    color: white;
}

.login-content .links a{
    margin-left: 4px;
}